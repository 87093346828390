import React from "react";
import { Link } from "gatsby";

import "./NewsArticle.scss";

const NewsArticle = ({ id, title, publishedDate, slug, description, tileImage }) => {

  return (
    <section className="section">
      <Link to={`/news/${slug}`} className="news" key={id}>
        <article>
          {tileImage && (
            <img src={tileImage.childImageSharp.fluid.src} className="news-img" />
          )}
          <div className="news-card">
            <h4>{title}</h4>
            <div dangerouslySetInnerHTML={{__html: description}}></div>
            <div className="news-footer">
              <p>{publishedDate}</p>
            </div>
          </div>
        </article>
      </Link>
    </section>
  )
}
  
export default NewsArticle;