import React from "react";
import Layout from "../components/Layout";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo";;
import NewsArticle from "../components/NewsArticle";

const News = ({data}) => {

  const { allStrapiNewsarticle: { nodes: news }} = data;
  const { newsarticlesTitle, newsarticlesDescription } = data?.seo?.nodes[0];

  return (
    <Layout>
      <SEO title={newsarticlesTitle} description={newsarticlesDescription}></SEO>
      <br></br>
      <section className="section-center fl-page">
        <h3>News Articles</h3>
        <div className="section-center news-center">
          {
            news.map(news => {
              return <NewsArticle key={news.id} {...news} />
            })
          }
        </div>
      </section>
      <br></br>
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiNewsarticle {
        nodes {
          title
          id
          slug
          description
          title
          publishedDate(formatString: "MMMM D YYYY")
          tileImage {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          paragraphs {
            contents
            images {
              name
              width
              height
              url
            }
          }
        }
      },
      seo: allStrapiSeo {
        nodes {
          newsarticlesDescription
          newsarticlesTitle
        }
      },
  }
`
export default News;